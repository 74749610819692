









import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import OurMission from "./OurMission.vue";
import Team from "./Team.vue";
import Faq from "./Faq.vue";

@Component({
  components: {
    PageHeader,
    OurMission,
    Team,
    Faq
  }
})
export default class AboutView extends Vue {}
