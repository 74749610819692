








































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Team extends Vue {
  tab = null;
  dialog = false;

  selectedMember = {
    img: "",
    title: "",
    fullName: "",
    bio: ""
  };

  management = [
    {
      img: require("@/assets/sacikalin.png"),
      title: "Müdür",
      fullName: "Prof. Dr. Sezgin Açıkalın",
      bio: ``
    },
    {
      img: require("@/assets/bkip.png"),
      title: "Müdür Yardımcısı",
      fullName: "Doç. Dr. Buket Kip Kayabaş",
      bio: ``
      // bio: `<p>2004 yılında Anadolu Üniversitesi Bilgisayar ve Öğretim Teknolojileri Öğretmenliği bölümünde lisans eğitimini tamamladı. Anadolu Üniversitesi Sosyal Bilimler Enstitüsü Uzaktan Eğitim Ana Bilim Dalında 2007 yılında yüksek lisans, 2017 yılında da doktora derecelerini aldı.</p>
      // <p>20 yılı aşkın süredir Anadolu Üniversitesi’nde gerçekleştirlen pek çok uzaktan eğitim projesinde içerik geliştirici, araştırmacı, yönetici vb. rollerde görev alan Kip Kayabaş; özellikle  <em>eğitimde açıklık</em>, <em>yaşam boyu öğrenme</em>, <em>açık ve uzaktan öğrenme</em> ve <em>kitlesel açık çevrimiçi dersler</em> bağlamında akademik çalışmalar yürütmektedir. Ayrıca 2007 yılından bu yana Anadolu Üniversitesi e-Sertifika Programları Yöneticiliği ve 2021 yılından bu yana da ANADOLUSEM Müdür Yardımcılığı görevlerini yürütmektedir.</p>`
    },
    {
      img: require("@/assets/fbozkurt.png"),
      title: "Müdür Yardımcısı",
      fullName: "Dr. Öğr. Üyesi Ferdi Bozkurt",
      bio: ``
    },
    {
      img: require("@/assets/ikybs.png"),
      title: "Eğitim Teknoloğu",
      fullName: "Dr. Öğr. Üyesi İlker Kayabaş",
      bio: ``
      // bio: `<p>Anadolu Üniversitesi Bilgisayar ve Öğretim Teknolojileri Öğretmenliği Bölümünden 2004 yılında mezun oldu. 2004-2015 yılları arasında Anadolu Üniversitesi Açıköğretim Fakültesi ÖTAG
      // Biriminde Müdür Yardımcısı olarak görev yaptı. Anadolu Üniversitesi Uzaktan Eğitim Anabilim
      // Dalında 2010’da yüksek lisansını ve 2017’de doktorasını tamamladı. Aynı anabilim dalında 2011
      // yılında araştırma görevlisi, 2019 yılında ise Dr. Öğr. Üyesi olarak atandı.</p>
      // <p>Başta Anadolu Üniversitesi olmak üzere Ford Otosan, TC. Milli Eğitim Bakanlığı, TC. Sağlık
      // Bakanlığı ve Türkiye İnternet Derneği gibi pek çok organizasyon için öğrenme yönetim sistemleri,
      // interaktif e-ders yazılımları, e-sınav arayüzleri ve e-portfolyo uygulamaları tasarladı ve geliştirdi. 2017’da İş Pasaportum ve 2019’de UTEx e-öğrenme girişimlerini kurdu.</p>`
    }
  ];

  managementBoard = [
    {
      img: require("@/assets/sacikalin.png"),
      title: "Başkan",
      fullName: "Prof. Dr. Sezgin Açıkalın",
      bio: ``
    },
    {
      img: require("@/assets/bkip.png"),
      title: "Üye",
      fullName: "Doç. Dr. Buket Kip Kayabaş",
      bio: ``
      // bio: `<p>2004 yılında Anadolu Üniversitesi Bilgisayar ve Öğretim Teknolojileri Öğretmenliği bölümünde lisans eğitimini tamamladı. Anadolu Üniversitesi Sosyal Bilimler Enstitüsü Uzaktan Eğitim Ana Bilim Dalında 2007 yılında yüksek lisans, 2017 yılında da doktora derecelerini aldı.</p>
      // <p>20 yılı aşkın süredir Anadolu Üniversitesi’nde gerçekleştirlen pek çok uzaktan eğitim projesinde içerik geliştirici, araştırmacı, yönetici vb. rollerde görev alan Kip Kayabaş; özellikle  <em>eğitimde açıklık</em>, <em>yaşam boyu öğrenme</em>, <em>açık ve uzaktan öğrenme</em> ve <em>kitlesel açık çevrimiçi dersler</em> bağlamında akademik çalışmalar yürütmektedir. Ayrıca 2007 yılından bu yana Anadolu Üniversitesi e-Sertifika Programları Yöneticiliği ve 2021 yılından bu yana da ANADOLUSEM Müdür Yardımcılığı görevlerini yürütmektedir.</p>`
    },
    {
      img: require("@/assets/erkek.png"),
      title: "Üye",
      fullName: "Doç. Dr. Muhammet Recep Okur",
      bio: ``
    },
    {
      img: require("@/assets/erkek.png"),
      title: "Üye",
      fullName: "Doç. Dr. Ceyhun Kavrayıcı",
      bio: ``
    },
    {
      img: require("@/assets/erkek.png"),
      title: "Üye",
      fullName: "Dr. Öğr. Üyesi Nejdet Karadağ",
      bio: ``
    }
  ];

  stuff = [
    {
      img: require("@/assets/zdinc.png"),
      title: "Öğrenci İşleri",
      fullName: "Züleyha Dinç",
      bio: ``
    },
    {
      img: require("@/assets/sakara.png"),
      title: "Öğrenci İşleri",
      fullName: "Sema Aydın Kara",
      bio: ``
    },
    {
      img: require("@/assets/ybatmaz.png"),
      title: "Büro Personeli",
      fullName: "Yasemin Batmaz",
      bio: ``
    },
    {
      img: require("@/assets/hokarginoglu.png"),
      title: "Büro Personeli",
      fullName: "H. Özgür Kargınoğlu",
      bio: ``
    },
    {
      img: require("@/assets/tture.png"),
      title: "Büro Personeli",
      fullName: "Türker Türe",
      bio: ``
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showBio(member: any) {
    this.selectedMember = member;
    this.dialog = true;
  }
}
