





























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Faq extends Vue {
  items = [
    {
      question: "Eğitimler yüz yüze mi?",
      answer:
        "<p>ANADOLUSEM eğitimleri yüz yüze, çevrimiçi yada harmanlanmış öğrenme yaklaşımlarına göre farklılık göstermektedir. Eğitim kataloğumuzu inceleyerek hangi eğitim kapsamında hangi öğrenme yaklaşımının kullanılacağına dair bilgi alabilirsiniz.</p>"
    },
    {
      question: "Eğitimler sonunda sertifika veriliyor mu?",
      answer: `<p>Evet, başarılı olunan her eğitimin sonunda başarı sertifikası verilmektedir.</p>`
    },
    {
      question: "ANADOLUSEM sertifikası ne ölçüde geçerlidir?",
      answer: `<p>Anadolu Üniversitesi Sürekli Eğitim Uygulama ve Araştırma Merkezi kamudan özel sektöre farklı alanlarda faaliyet gösteren pek çok iş organizasyonu tarafından tercih edilmektedir. Daha detaylı bilgi için "başarı hikayeleri" bölümüne göz atabilirsiniz.</p>`
    }
  ];
}
