



























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class OurMission extends Vue {
  dialog = false;
}
